<template>
  <div>
    <template v-if="grillingSpecial.active">
      <div style="background-color: #232222">
        <v-container fluid class="py-0">
          <v-row class="my-0" align="center">
            <v-col class="py-0 px-0" cols="12" sm="6">
              <v-img
                :src="'https://huesodevacacms.actstudio.xyz/'+grillingSpecial.cover.path"
                height="calc(100vh - 80px)"
                class="align-end pb-3 d-none d-sm-flex"
              >
                <v-img
                  src="../assets/img/grilling-day.png"
                  max-width="500"
                  class="ml-6"
                ></v-img>
              </v-img>

              <v-img
                :src="'https://huesodevacacms.actstudio.xyz/'+grillingSpecial.cover.path"
                class="align-end pb-3 d-sm-none"
              >
                <v-img
                  src="../assets/img/grilling-day.png"
                  max-width="285"
                  class="ml-6"
                ></v-img>
              </v-img>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-container class="py-0">
                <v-row class="my-0">
                  <v-col offset-sm="2" class="py-0">
                    <span
                      class="druk-wide-bold d-block white--text"
                      style="font-size: 40px"
                      >{{grillingSpecial.name}}</span
                    >
                    <span
                      class="druk-medium d-block white--text"
                      style="font-size: 35px"
                      >{{grillingSpecial.subName}}</span
                    >
                    <p class="white--text d-block" style="max-width: 350px">
                      {{grillingSpecial.description}}
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
    <template v-for="(categoria, c) in categorias">
      <div
        :style="'background-color: ' + categoria.colorPrincipal"
        :key="'menu-categoria-' + c"
        v-bind:class="{ 'white--text': categoria.colorPrincipal == '#232222' }"
      >
        <v-container fluid class="py-0">
          <v-row class="my-0 menu-row" align="center">
            <v-col cols="12" sm="6" class="px-0 py-0">
              <v-img
                :src="
                  'https://huesodevacacms.actstudio.xyz/' + categoria.cover.path
                "
                class="align-end d-sm-none"
              >
              </v-img>

              <template v-if="c === 0">

                <v-img
                  :src="
                    'https://huesodevacacms.actstudio.xyz/' + imagen0
                  "
                  class="align-end d-none d-sm-flex"
                  style="height: calc(100vh - 80px)"
                >
                  <v-img
                    :src="
                      'https://huesodevacacms.actstudio.xyz/' +
                      categoria.icono.path
                    "
                    :key="'categoria-cover-' + c"
                    max-width="500"
                    class="mx-auto cursor-pointer"
                  ></v-img>
                  <br />
                </v-img>

              </template>

              <template v-if="c === 1">

                <v-img
                  :src="
                    'https://huesodevacacms.actstudio.xyz/' + imagen1
                  "
                  class="align-end d-none d-sm-flex"
                  style="height: calc(100vh - 80px)"
                >
                  <v-img
                    :src="
                      'https://huesodevacacms.actstudio.xyz/' +
                      categoria.icono.path
                    "
                    :key="'categoria-cover-' + c"
                    max-width="500"
                    class="mx-auto cursor-pointer"
                  ></v-img>
                  <br />
                </v-img>

              </template>

              <template v-if="c === 2">

                <v-img
                  :src="
                    'https://huesodevacacms.actstudio.xyz/' + imagen2
                  "
                  class="align-end d-none d-sm-flex"
                  style="height: calc(100vh - 80px)"
                >
                  <v-img
                    :src="
                      'https://huesodevacacms.actstudio.xyz/' +
                      categoria.icono.path
                    "
                    :key="'categoria-cover-' + c"
                    max-width="500"
                    class="mx-auto cursor-pointer"
                  ></v-img>
                  <br />
                </v-img>

              </template>

              <template v-if="c === 3">

                <v-img
                  :src="
                    'https://huesodevacacms.actstudio.xyz/' + imagen3
                  "
                  class="align-end d-none d-sm-flex"
                  style="height: calc(100vh - 80px)"
                >
                  <v-img
                    :src="
                      'https://huesodevacacms.actstudio.xyz/' +
                      categoria.icono.path
                    "
                    :key="'categoria-cover-' + c"
                    max-width="500"
                    class="mx-auto cursor-pointer"
                  ></v-img>
                  <br />
                </v-img>

              </template>

              <template v-if="c === 4">

                <v-img
                  :src="
                    'https://huesodevacacms.actstudio.xyz/' + imagen4
                  "
                  class="align-end d-none d-sm-flex"
                  style="height: calc(100vh - 80px)"
                >
                  <v-img
                    :src="
                      'https://huesodevacacms.actstudio.xyz/' +
                      categoria.icono.path
                    "
                    :key="'categoria-cover-' + c"
                    max-width="500"
                    class="mx-auto cursor-pointer"
                  ></v-img>
                  <br />
                </v-img>

              </template>

              <template v-if="c === 5">

                <v-img
                  :src="
                    'https://huesodevacacms.actstudio.xyz/' + imagen5
                  "
                  class="align-end d-none d-sm-flex"
                  style="height: calc(100vh - 80px)"
                >
                  <v-img
                    :src="
                      'https://huesodevacacms.actstudio.xyz/' +
                      categoria.icono.path
                    "
                    :key="'categoria-cover-' + c"
                    max-width="500"
                    class="mx-auto cursor-pointer"
                  ></v-img>
                  <br />
                </v-img>

              </template>

            </v-col>
            <v-col cols="12" sm="6">
              <div class="py-6 py-sm-0">
                <template v-for="(producto, p) in productos">
                  <template v-if="producto.categoria._id == categoria._id">
                    <v-row :key="'row-producto-' + p" justify="center">
                      <v-col cols="11" sm="10">
                        <div :key="'producto-' + p" @mouseover="setProduct(c,p)">
                          <span class="druk-medium menu-pre-title-size">
                            {{ producto.preName }}
                          </span>
                          <span class="druk-wide-bold menu-title-size">
                            {{ producto.etiquetaName }}
                          </span>
                          <span class="d-block druk-medium menu-pre-title-size">
                            {{ producto.postName }}
                          </span>
                          <span>
                            {{ producto.portionSize }}
                          </span>
                          <p class="mb-0">
                            {{ producto.description }}
                          </p>
                          <span class="bold">
                            {{ producto.price }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                </template>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data () {
    return {
      categorias: [],
      productos: [],
      selected0: 0,
      selected1: 0,
      selected2: 0,
      selected3: 0,
      selected4: 0,
      selected5: 0,
      selected6: 0,
      selected7: 0,
      loaded: false,
      grillingSpecial: {
        cover: {
          path: ''
        }
      }
    }
  },
  computed: {
    imagen0: function () {
      if (this.loaded) {
        return this.productos[this.selected0].cover.path
      } else {
        return 0
      }
    },
    imagen1: function () {
      if (this.loaded) {
        return this.productos[this.selected1].cover.path
      } else {
        return 0
      }
    },
    imagen2: function () {
      if (this.loaded) {
        return this.productos[this.selected2].cover.path
      } else {
        return 0
      }
    },
    imagen3: function () {
      if (this.loaded) {
        return this.productos[this.selected3].cover.path
      } else {
        return 0
      }
    },
    imagen4: function () {
      if (this.loaded) {
        return this.productos[this.selected4].cover.path
      } else {
        return 0
      }
    },
    imagen5: function () {
      if (this.loaded) {
        return this.productos[this.selected5].cover.path
      } else {
        return 0
      }
    },
    imagen6: function () {
      if (this.loaded) {
        return this.productos[this.selected6].cover.path
      } else {
        return 0
      }
    },
    imagen7: function () {
      if (this.loaded) {
        return this.productos[this.selected7].cover.path
      } else {
        return 0
      }
    }
  },
  components: {},
  beforeMount: function () {
    const aux = this
    fetch(
      'https://huesodevacacms.actstudio.xyz/api/collections/get/Categorias',
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filter: {},
          fields: {},
          sort: {}
        })
      }
    )
      .then((res) => res.json())
      .then(function (res) {
        aux.categorias = res.entries
      })

    fetch(
      'https://huesodevacacms.actstudio.xyz/api/collections/get/Productos',
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filter: {},
          fields: {},
          sort: {}
        })
      }
    )
      .then((res) => res.json())
      .then(function (res) {
        aux.productos = res.entries
        aux.loaded = true
      })

    fetch('https://huesodevacacms.actstudio.xyz/api/singletons/get/GrillingSpecial')
      .then((data) => data.json())
      .then(function (data) {
        aux.grillingSpecial = data
      })
  },
  methods: {
    setProduct: function (category, product) {
      if (category === 0) {
        this.selected0 = product
      }
      if (category === 1) {
        this.selected1 = product
      }
      if (category === 2) {
        this.selected2 = product
      }
      if (category === 3) {
        this.selected3 = product
      }
      if (category === 4) {
        this.selected4 = product
      }
      if (category === 5) {
        this.selected5 = product
      }
      if (category === 6) {
        this.selected6 = product
      }
      if (category === 7) {
        this.selected7 = product
      }
    }
  }
}
</script>
